import {Alert, Box, Button, Card, Container, Grid, LinearProgress, Stack} from '@mui/material';
import Typography from '@mui/material/Typography';
import type * as React from 'react';
import {useEffect, useState} from 'react';
import ConfidentialityModal from '@/components/ConfidentialityModal';
import ProjectTable from '@/components/ProjectTable';
import TermsAndConditionsModal from '@/components/TermsAndConditionsModal';
import {useUpdateTermsAndConditions} from '@/mutations/useUpdateTermsAndConditions';
import {useFmUserQuery} from '@/queries/fmUser';

const Home = () : React.ReactElement => {
    const fmUserQuery = useFmUserQuery();
    const updateTermsAndConditions = useUpdateTermsAndConditions();
    const [confidentialityModalOpen, setConfidentialityModalOpen] = useState(true);

    const handleConfirmAgreement = () => {
        // update user then setConfidentialityModalOpen to false
        setConfidentialityModalOpen(false);
    };

    useEffect(() => {
        if (fmUserQuery.data && fmUserQuery.data.dateAcceptedTermsAndConditions !== '') {
            setConfidentialityModalOpen(false);
        }
    }, [fmUserQuery.data]);

    if (fmUserQuery.isError) {
        return <Alert severity='error'>Failed to load user.</Alert>;
    }

    if (!fmUserQuery.data) {
        return <LinearProgress/>;
    }

    return <Container maxWidth='lg' sx={{p: 2}}>
        {fmUserQuery.data.dateAcceptedTermsAndConditions === '' && <Stack
            sx={{
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'center',
                m: 3,
            }}
        >
            <Typography variant='h5' sx={{fontWeight: 800}}>Terms and Conditions</Typography>
            <Box sx={{mt: 2}}>
                Any information or materials either disclosed by Krypton herein that relates to the business or
                activities of Krypton Solutions, LLC (“Krypton”) or any of its affiliates or subsidiaries and is not
                generally known to the public, including but not limited to manufacturing processes, product
                specifications and designs, technical know-how, financial information, and customer data, shall be
                deemed Confidential Information, whether or not designated as such.
            </Box>
            <Box sx={{mt: 2}}>
                The undersigned shall hold in confidence and not directly or indirectly disclose, publish, reveal,
                divulge, or otherwise make known any Confidential Information to any third party, except as may be
                required by applicable law, without the prior, written consent of Krypton. The undersigned shall protect
                the Confidential Information with the same degree of care the undersigned would use regarding its own
                proprietary/confidential information of like importance, but in no event less than reasonable care, and
                shall use all reasonable endeavors to prevent unauthorized publication or disclosure of the Confidential
                Information.
            </Box>
            <Box sx={{mt: 2}}>
                The undersigned acknowledges that information disclosed herein may contain data subject to export
                control restrictions and that compliance with applicable Government regulations may be necessary. The
                undersigned agrees to obtain all necessary approvals prior to disclosing any information subject to
                export control restrictions to foreign persons, businesses, or governments. The undersigned agrees to
                fully comply with the United States export control laws and regulations, specifically including, but not
                limited to, ITAR, 22 CFR 120 et seq.; Foreign Assets Control Regulations, 31 C.F.R. 500-598; Export
                Administration Regulations, 15 C.F.R. 730-774; including the requirement for obtaining export licenses,
                if applicable.
            </Box>
            <Box sx={{mt: 2}}>
                The undersigned acknowledges that any breach or threatened breach by it, may cause irreparable damage to
                Krypton and agrees that Krypton shall be entitled to injunctive or other equitable relief, without being
                required to post bond or other security, to prevent such actual or threatened unauthorized use or
                disclosure and to specifically enforce the terms and provisions hereof. The foregoing remedies are
                supplemental to and do not exclude any other remedies available at law or in equity.
            </Box>
            <Button
                variant='outlined'
                onClick={() => {
                    updateTermsAndConditions.mutate({});
                }}
                sx={{mt: 2}}
            >
                Accept
            </Button>
        </Stack>}
        {fmUserQuery.data.dateAcceptedTermsAndConditions !== '' && <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
                <Stack>
                    <Card
                        sx={{
                            my: 1,
                            p: 2,
                        }}
                    >
                        <Stack
                            sx={{
                                flexDirection: {xs: 'column', md: 'row'},
                                justifyContent: 'space-between',
                            }}
                        >
                            <Stack
                                sx={{
                                    flexDirection: {xs: 'column', md: 'row'},
                                }}
                            >
                                <Box
                                    sx={{
                                        mr: 4,
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontWeight: '800',
                                            display: 'inline',
                                            mr: 1,
                                        }}
                                    >
                                        Name:
                                    </Typography>
                                    {fmUserQuery.data.name}
                                </Box>
                                <Box>
                                    <Typography
                                        sx={{
                                            fontWeight: '800',
                                            display: 'inline',
                                            mr: 1,
                                        }}
                                    >
                                        Company:
                                    </Typography>
                                    {fmUserQuery.data.company}
                                </Box>
                            </Stack>
                            <Stack
                                sx={{
                                    flexDirection: 'row',
                                    flexGrow: 1,
                                    justifyContent: {xs: 'flex-beginning', md: 'flex-end'},
                                }}
                            >
                                <Stack
                                    sx={{
                                        flexDirection: 'column',
                                    }}
                                >
                                    <Box>
                                        {fmUserQuery.data.phoneNumber}
                                    </Box>
                                    <Box>
                                        {fmUserQuery.data.email}
                                    </Box>
                                </Stack>
                            </Stack>
                        </Stack>
                    </Card>
                    <ProjectTable data={fmUserQuery.data.projects}/>
                </Stack>
            </Grid>
            <Grid item xs={12} md={12}>
                <TermsAndConditionsModal/>
                <ConfidentialityModal open={confidentialityModalOpen} confirmAgreement={handleConfirmAgreement}/>
            </Grid>
        </Grid>}
    </Container>;
};

export default Home;
