import {useAuth0} from '@auth0/auth0-react';
import {Box, Stack, useMediaQuery, useTheme} from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import type * as React from 'react';
import {Outlet} from 'react-router-dom';
import LogoImage from './images/logo.png';
import Hamburger from '@/components/Navigation/Hamburger.tex';
import UserHeaderLinks from '@/components/Navigation/UserHeaderLinks';

const Layout = () : JSX.Element => {
    const {user} = useAuth0();
    const theme = useTheme();
    const isMdOrSmaller = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <>
            <AppBar position="static" sx={{backgroundColor: '#000'}}>
                <Toolbar sx={{}}>
                    <Stack
                        sx={{
                            width: '100%',
                            flexDirection: {xs: 'column', md: 'row'},
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}
                    >
                        <Box
                            component="img"
                            alt="Krypton Logo"
                            src={LogoImage}
                            sx={{
                                maxWidth: '150px',
                                m: 2,
                            }}
                        />
                        <Typography variant="h6">
                            Customer Portal
                        </Typography>
                        <Stack>
                            {user && <Box>
                                <UserHeaderLinks/>
                            </Box>}
                        </Stack>
                    </Stack>
                </Toolbar>
            </AppBar>
            <main>
                <Outlet/>
            </main>
        </>
    );
};

export default Layout;
