import {Auth0Provider} from '@auth0/auth0-react';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import {createTheme, CssBaseline} from '@mui/material';
import {ThemeProvider} from '@mui/material/styles';
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';
import {ReactQueryDevtools} from '@tanstack/react-query-devtools';
import {SnackbarProvider} from 'notistack';
import {StrictMode} from 'react';
import {createRoot} from 'react-dom/client';
import {BrowserRouter} from 'react-router-dom';
import App from './App';
import IdentityProvider from '@/components/IdentityProvider';
import {OidcSecure} from '@/components/Oidc';

const theme = createTheme();
const queryClient = new QueryClient();
const container = document.getElementById('root');

if (!container) {
    throw new Error('Root element missing');
}

const root = createRoot(container);
root.render((
    <StrictMode>
        <ThemeProvider theme={theme}>
            <CssBaseline/>
            <Auth0Provider
                domain={import.meta.env.VITE_APP_AUTH0_DOMAIN}
                clientId={import.meta.env.VITE_APP_AUTH0_CLIENT_ID}
                authorizationParams={{
                    scope: 'openid profile email',
                    redirect_uri: window.location.origin + '/authorized',
                    audience: import.meta.env.VITE_APP_AUTH0_AUDIENCE,
                }}
                useRefreshTokens={true}
                cacheLocation='localstorage'
            >
                <OidcSecure>
                    <QueryClientProvider client={queryClient}>
                        <SnackbarProvider maxSnack={1}>
                            <BrowserRouter>
                                <IdentityProvider>
                                    <App/>
                                </IdentityProvider>
                            </BrowserRouter>
                        </SnackbarProvider>
                        <ReactQueryDevtools initialIsOpen={false}/>
                    </QueryClientProvider>
                </OidcSecure>
            </Auth0Provider>
        </ThemeProvider>
    </StrictMode>
));
