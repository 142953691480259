import type * as React from 'react';
import {Route, Routes} from 'react-router-dom';
import Layout from './Layout';
import Home from './pages/Home';
import Authorized from '@/pages/Authorized';

const App = () : React.ReactElement => {
    return (
        <Routes>
            <Route path="/" element={<Layout/>}>
                <Route index element={<Home/>}/>
            </Route>
            <Route path="/authorized" element={<Layout/>}>
                <Route index element={<Authorized/>}/>
            </Route>
        </Routes>
    );
};

export default App;
