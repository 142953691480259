import {useAuth0} from '@auth0/auth0-react';
import type {UseQueryResult} from '@tanstack/react-query';
import {useQuery} from '@tanstack/react-query';
import useOidcFetch from '@/hooks/useOidcFetch';
import {mapRawToFmUser} from '@/mappers/fmUser';
import type {FmUser, RawFmUser} from '@/types/fmUser';
import {apiUrl} from '@/utils/api';

export const useFmUserQuery = () : UseQueryResult<FmUser> => {
    const fetch = useOidcFetch();
    const {user} = useAuth0();

    if (!user) {
        throw new Error('User must be signed in');
    }

    return useQuery({
        queryKey: ['user'],
        queryFn: async ({signal}) => {
            if (!user.email) {
                throw new Error('Unable to fetch user.');
            }

            const url = apiUrl('/user/user/get');
            const response = await fetch(url.toString(), {signal}) as Response;

            if (!response.ok) {
                throw new Error('Unable to fetch user.');
            }

            const rawUser = (await response.json() as RawFmUser);

            return mapRawToFmUser(rawUser);
        },
    });
};
