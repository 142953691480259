import type {FmUser, RawFmUser} from '@/types/fmUser';

// const convertToDate = (dateString : string) : (Date | string) => {
//     if (moment(dateString, 'MM/DD/YYYY', true).isValid()) {
//         return moment(dateString, 'MM/DD/YYYY', true).toDate();
//     }
//
//     return '';
// };

export const mapRawToFmUser = (raw : RawFmUser) : FmUser => {
    return {
        name: `${raw.fieldData['Title']} ${raw.fieldData['First']} ${raw.fieldData['Last']}`,
        company: raw.fieldData['Company'],
        phoneNumber: raw.fieldData['Office Phone'],
        email: raw.fieldData['Email'],
        ID: raw.fieldData['CUSTOMER ID'],
        dateAcceptedTermsAndConditions: raw.fieldData['Agreement_Approved_Date'],
        projects: raw.projects.map(project => {
            return {
                recordId: project.fieldData.recordId,
                creationDate: project.fieldData['Creation Timestamp'],
                jobNumber: project.fieldData['JOB NO ID'],
                projectName: project.fieldData['Master Project Name'],
                dueDate: project.fieldData['Customer Due Date Final'],
                status: project.fieldData['z_Status_Bucket'],
                statusDate: project.fieldData['z_Status_Bucket_Update_Date'],
                comments: project.fieldData['z_Status_Bucket_Canned_Reasons'],

                poNumber: '••Related Project Number••',
                modId: project.fieldData.modId,
            };
        }),
    };
};
