import {useAuth0} from '@auth0/auth0-react';
import {Box, Container} from '@mui/material';
import type * as React from 'react';
import {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {useUsageLog} from '@/mutations/useUsageLog';

const Authorized = () : React.ReactElement => {
    const navigate = useNavigate();
    const {user, isLoading} = useAuth0();
    const createUsageLog = useUsageLog({
        callback: () => {
            navigate('/');
        },
    });

    let doLog = true;

    if (isLoading) {
        return <Box>Loading ...</Box>;
    }

    useEffect(() => {
        if (user && doLog) {
            doLog = false;

            createUsageLog.mutate(
                user.email,
            );
        }
    }, [user]);

    return <Container maxWidth='lg' sx={{p: 2}}>
        {user ? 'Authorizing' : 'Not Authorized'}
    </Container>;
};

export default Authorized;
