import {Box, Link, Stack} from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import type * as React from 'react';
import {useState} from 'react';

const TermsAndConditionsModal = () : React.ReactElement => {
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return <Box>
        {open && <Dialog
            open={true}
            maxWidth='md'
            fullWidth={true}
            onClose={handleClose}
            aria-labelledby="terms-and-conditions-title"
            aria-describedby="terms-and-conditions-description"
        >
            <DialogTitle id="terms-and-conditions-title">
                Terms & Conditions
            </DialogTitle>
            <DialogContent sx={{fontSize: '10px'}}>
                <Box sx={{fontWeight: 800, textAlign: 'center', width: '100%', fontSize: '16px'}}>TERMS AND CONDITIONS</Box>
                <Box sx={{fontWeight: 800, fontSize: '12px', mt: 2}}>SECTION I: TERMINOLOGY</Box>
                <Box sx={{fontWeight: 800, mt: 1}}>1. Definitions</Box>
                <Box>1.1 The following terms shall have the means set forth below:</Box>
                <Box>(a) “Agreement” shall mean the Purchase Order, Subcontract or Contract, Memorandum of Understanding, Statement of Work, these General Terms and Conditions, and any other documents incorporated herein.</Box>
                <Box>(b) “Business Day shall mean a day, other than a Saturday, Sunday, or public holiday, in which banks in the United States are open for business.</Box>
                <Box>(c) “Confidential Information” shall mean any information or materials, whether provided in writing, orally, or visually, which relates to the business or activities of Krypton that is not generally known to the public, including but not limited to, apparatus, Intellectual Property, modules, samples, prototypes, parts, manufacturing processes and equipment, product specifications and designs, technical know-how, business and marketing plans, financial information, customer and supplier data and research and development activities.</Box>
                <Box>(d) “Contract” means the instrument of contracting, such as Purchase Order, or other such type designation, including all referenced documents, exhibits and attachments.</Box>
                <Box>(e) “Customer” shall mean the entity with whom Krypton has, or anticipates having, a contractual relationship to provide Work to pursuant to this Agreement.</Box>
                <Box>(f) “Electronic Signature” shall mean any symbol or other data in digital form attached this Agreement used as verification of intent by a person to sign this Agreement.</Box>
                <Box>(g) “Intellectual Property,” unless otherwise expressly set forth in this Agreement, shall mean any creation of the mind, including but not limited to, any design, idea, discovery, improvement, invention, symbols, names, images, trade secrets, copy rights, or work of any kind first made or conceived by Krypton, or which is derived from the use of information supplied by Krypton, pursuant to this Agreement.</Box>
                <Box>(h) “Krypton” shall mean Krypton Solutions, LLC, and all its affiliated companies and subsidiaries, if any.</Box>
                <Box>(i)  “Materials” shall mean the components or consumables required to fabricate a product.</Box>
                <Box>(j) “Party” shall mean the Customer, Supplier, or Subcontractor pursuant to this Agreement.</Box>
                <Box>(k) “Purchase Order” or “PO” as used in any document forming part of this Agreement shall mean this Agreement.</Box>
                <Box>(l) “Seller” shall mean Supplier or Subcontractor pursuant to this Agreement.</Box>
                <Box>(m) “Software” shall mean any operating information used by a computer, including but not limited to, any modifications, firmware, program, enhancements, upgrades, subsequent versions, or releases, provided by Krypton pursuant to this Agreement.</Box>
                <Box>(m) “Supplier” shall mean the entity with whom Krypton has, or anticipates having, a contractual relationship with to purchase Materials pursuant to this Agreement.</Box>
                <Box>(o) “Subcontractor” shall mean the entity with whom Krypton has, or anticipates having, a contractual relationship with to obtain Work from pursuant to this Agreement.</Box>
                <Box>(p) “Taxes” shall mean all applicable federal, state, and local taxes, charges, duties, impositions, tariffs, and all other fees imposed by any government.</Box>
                <Box>(q) “Work” shall mean the Goods or Service as expressly identified in this Agreement.</Box>
                <Box sx={{fontWeight: 800, fontSize: '12px', mt: 2}}>SECTION II: WORK PROVIDED BY KRYPTON</Box>
                <Box>The terms contained in this Section II shall only apply to the Customer with whom Krypton has, or anticipates having, a contractual relationship with to provide Work.</Box>
                <Box sx={{fontWeight: 800, mt: 1}}>1. Schedule</Box>
                <Box>1.1 Unless otherwise expressly set forth in this Agreement, any schedule forecast provided to Customer by Krypton is for planning purposes only and shall not constitute a commitment for delivery by Krypton.</Box>
                <Box>1.2 If Krypton is unable to make the scheduled delivery pursuant to the terms of this Agreement, Krypton shall promptly notify Customer in writing of any actual or potential delay in the delivery of Work and shall include a revised schedule of delivery.</Box>
                <Box>1.3 Any change requested by Customer that causes a change to the terms of this Agreement, shall not be binding upon Krypton unless accepted in writing by Krypton and Customer.</Box>
                <Box sx={{fontWeight: 800, mt: 1}}>2. Delivery</Box>
                <Box>2.1 For Agreements in which Krypton provides Customer with Contract Manufacturing Services:</Box>
                <Box>(a) Krypton shall prepare and package all Work in a manner that minimizes damage and deterioration.</Box>
                <Box>(b) Krypton shall deliver all Work in a manner that: (i) follows good commercial practice; (ii) is acceptable to common carriers for shipment at the lowest rate; and (iii) is adequate to ensure safe delivery.</Box>
                <Box>(c) F.O.B Place of Origin shall be the Shipping Point Incoterms 2020, unless otherwise expressly set forth in this Agreement.</Box>
                <Box>2.2 For Agreements in which Krypton provides Customer with Design Engineering Services:</Box>
                <Box>(a) Upon receipt of payment, Krypton shall transfer ownership of the Intellectual Property specified in this Agreement to Customer and execute all necessary documents to perfect the Customer’s title to any acquired Intellectual Property.</Box>
                <Box>(b) Krypton shall retain all Intellectual Property belonging to Krypton prior to entering into this Agreement. Customer acknowledges that nothing in this Agreement is intended to transfer any prior Intellectual Property between the parties.</Box>
                <Box>(c) Krypton shall retain all proprietary rights in concepts, ideas, writings, trade secrets, and inventions that may arise during the preparation of providing Work to Customer.</Box>
                <Box sx={{fontWeight: 800, mt: 1}}>3. Inspection, Acceptance, and Rejection</Box>
                <Box>3.1 Customer shall have the right to audit Krypton’s records to ensure compliance with the terms of this Agreement. Krypton shall make all reasonably requested data available to the Customer.</Box>
                <Box>3.2 Customer shall be permitted to enter Krypton’s facility, at a reasonable time, to inspect any Work by Krypton performed pursuant to this Agreement.</Box>
                <Box>3.3 All Work provided by Krypton pursuant to this Agreement shall be deemed accepted unless rejected by Customer within thirty (30) days after delivery, notwithstanding any prior inspection or obligation to pay. Customer acknowledges that nothing in this Agreement shall relieve Customer from its obligation to remit payment in a timely manner.</Box>
                <Box>3.4 If Customer receives damaged or nonconforming Work, Customer may, at its sole option and expense: (i) return the Work to Krypton and request Krypton promptly correct or replace the damaged or nonconforming Work; or (ii) correct the damaged or nonconforming Work.</Box>
                <Box sx={{fontWeight: 800, mt: 1}}>4. Pay Terms</Box>
                <Box>4.1 All pricing provided pursuant to this Agreement shall be in U.S. Dollars.</Box>
                <Box>4.2 Unless otherwise expressly set forth in this Agreement, terms of payment shall be net thirty (30) days from the date provided in the Purchase Order.</Box>
                <Box>4.3 Payment shall be deemed to have been made as of the date of mailing by Customer, if by mail, or the date of the electronic funds transfer.</Box>
                <Box>4.4 Unless otherwise expressly set forth in this Agreement, prices include all Taxes, which shall be listed separately on the Purchase Order. Prices shall not include any Taxes for which Customer has furnished a valid exemption certificate or other evidence of exemption.</Box>
                <Box>4.5 Payment by Customer, in whole or in part, shall constitute the Customer’s acceptance of this Agreement.</Box>
                <Box sx={{fontWeight: 800, mt: 1}}>5.  Late Fees</Box>
                <Box>5.1 If Customer shall fail to pay any payment when due, Customer shall pay to Krypton, as an additional payment, a late charge in an amount equal to five percent (5%) of the overdue payment. For every thirty (30) day period thereafter that payment remains unpaid, another late charge equal to five percent (5%) shall be computed and charged to Customer.</Box>
                <Box>5.2 No sum hereunder shall be due or payable in excess of the maximum amount permitted by law. Customer agrees that the amount of such late charge represents a reasonable estimate of the cost and expense that would be incurred by Krypton in processing each delinquent payment by Customer and that such late charge shall be paid to Krypton as liquidated damages. The payment of such late charge shall not excuse payment of the amount due by Customer pursuant to this Agreement.</Box>
                <Box sx={{fontWeight: 800, mt: 1}}>6. Warranties</Box>
                <Box>6.1 Krypton warrants that all Work provided pursuant to this Agreement shall be in accordance with the current standards of care and diligence normally practiced by recognized firms performing services of a similar nature and in compliance with all applicable federal, state, and local laws.</Box>
                <Box>6.2 Krypton warrants that all Work provided to Customer by Krypton pursuant to this Agreement, shall (i) only contain Materials obtained directly from authorized sources (ii) not be or contain counterfeit parts; (iii) contain only authentic, unaltered labels and other markings; and (iv) have documentation made available upon request that authenticates traceability to the applicable authorized source.</Box>
                <Box>6.3 Krypton represents and warrants that its employees, personnel, agents, or contractors providing Work pursuant to this Agreement shall be trained, experienced, professional, and where applicable, licensed, certified, and bonded.</Box>
                <Box>6.4 Krypton shall warrant against defects in performance attributable to errors in the Work provided by Krypton pursuant to this Agreement for a period of ninety (90) days and shall reperform Work as may be necessary to remedy such errors. All costs incurred by Krypton in taking any corrective action shall be subject to an aggregate limit equal to ten percent (10%) of the price provided in this Agreement.</Box>
                <Box>6.5 If any Work provided to Customer by Krypton is modified by Customer, all warranties provided in this Section 6 shall be deemed null and void.</Box>

                <Box sx={{fontWeight: 800, fontSize: '12px', mt: 2}}>SECTION III: WORK PROVIDED TO KRYPTON</Box>
                <Box>The terms contained in this Section III shall only apply to the Seller with whom Krypton has, or anticipates having, a contractual relationship with to obtain Materials or Work.</Box>
                <Box sx={{fontWeight: 800, mt: 1}}>1. Schedule</Box>
                <Box>1.1 Unless otherwise expressly set forth in this Agreement, the Seller shall strictly adhere to all schedules. Time is and shall remain of the essence in the performance of this Agreement.</Box>
                <Box>1.2 If the Seller is unable to make the scheduled delivery pursuant to the terms of this Agreement, the Seller shall promptly notify Krypton in writing of any actual or potential delay in the delivery of Materials or Work and shall include a revised schedule of delivery. The Seller agrees that such notice shall not constitute a waiver to Krypton’s rights and remedies hereunder.</Box>
                <Box>1.3 Any change requested by the Seller that causes a change to the terms of this Agreement, shall not be binding upon Krypton unless accepted in writing by Krypton and the Seller.</Box>
                <Box sx={{fontWeight: 800, mt: 1}}>2. Delivery</Box>
                <Box>2.1 Seller shall prepare and package all Materials or Work in a manner that minimizes damage and deterioration.</Box>
                <Box>2.2 Unless otherwise expressly set forth in this Agreement, Seller shall label all Materials or Work in accordance with the requirements indicated in the Purchase Order.</Box>
                <Box>2.3 Seller shall deliver all Materials or Work in a manner that: (i) follows good commercial practice; (ii) is acceptable to common carriers for shipment at the lowest rate; and (iii) is adequate to ensure safe delivery.</Box>
                <Box>2.4 EXW shall be the Destination, Incoterms 2020, unless otherwise expressly set forth in this Agreement.</Box>
                <Box sx={{fontWeight: 800, mt: 1}}>3. Inspection, Acceptance, and Rejection</Box>
                <Box>3.1 Krypton shall have the right to audit Sellers records to ensure compliance with the terms of this Agreement. Seller shall make all reasonably requested data available to the Seller.</Box>
                <Box>3.2 Krypton shall be permitted to enter Seller’s facility, at a reasonable time, to inspect any Work by Seller performed pursuant to this Agreement.</Box>
                <Box>3.3 All Work provided by Seller pursuant to this Agreement shall be deemed accepted unless rejected by Krypton within (30) days after delivery, notwithstanding any prior inspection or obligation to pay.</Box>
                <Box>3.4 If Krypton receives damaged or nonconforming Materials or Work, Krypton may, at its sole option: (i) return the Materials or Work for a refund; (ii) return the Materials or Work to Seller and request Seller promptly correct or replace the damaged or nonconforming Materials or Work; or (ii) obtain the Materials or Work from another source. Seller shall be liable for any costs incurred relating to Krypton’s rejection of the nonconforming Materials or Work.</Box>
                <Box>3.5 Seller shall reimburse Krypton for any monetary penalties assigned to Krypton by Customer relating to the delivery of nonconforming Materials or Work from Seller.</Box>
                <Box sx={{fontWeight: 800, mt: 1}}>4. Pay Terms</Box>
                <Box>4.1 All pricing provided pursuant to this Agreement shall be in U.S. Dollars.</Box>
                <Box>4.2 Unless otherwise expressly set forth in this Agreement, terms of payment shall be net thirty (30) days from the date provided in the Purchase Order.</Box>
                <Box>4.3 Payment shall be deemed to have been made as of the date of mailing by Krypton, if by mail, or the date of the electronic funds transfer.</Box>
                <Box>4.4 Unless otherwise expressly set forth in this Agreement, prices include all Taxes, which shall be listed separately on the Purchase Order.</Box>
                <Box>4.5 If requested by Krypton, Seller shall provide Krypton with a W-9 Form in accordance with IRS regulations.</Box>
                <Box sx={{fontWeight: 800, mt: 1}}>6. Warranties</Box>
                <Box>6.1 Seller warrants that all Materials and Work provided pursuant to this Agreement shall be in accordance with the current standards of care and diligence normally practiced by recognized firms performing services of a similar nature and in compliance with all applicable federal, state, and local laws.</Box>
                <Box>6.2 Seller warrants that all Materials and Work provided pursuant to this Agreement, shall (i) only contain Materials obtained directly from authorized sources (ii) not be or contain counterfeit parts; (iii) contain only authentic, unaltered labels and other markings; and (iv) have documentation made available upon request that authenticates traceability to the applicable authorized source.</Box>
                <Box>6.3 Seller represents and warrants that its employees, personnel, agents, or contractors providing Materials or Work pursuant to this Agreement shall be ethical, trained, experienced, professional, and where applicable, licensed, certified and bonded.</Box>
                <Box>6.4 Seller represents and warrants that its employees, personnel, agents, or contractors providing Materials or Work pursuant to this Agreement are aware of their contribution to any applicable conformity and safety requirements.</Box>
                <Box>6.5 Seller shall warrant against defects in performance attributable to errors in the Materials or Work provided by Seller pursuant to this Agreement for a period of ninety (90) days and shall perform all corrective action as may be necessary to remedy such errors.</Box>
                <Box>6.6. Seller acknowledges and agrees that all warranties shall inure to the benefit of both Krypton and the Customer for a period of ninety (90) days following acceptance.</Box>
                <Box>6.7 Seller shall be liable for and save Krypton harmless from any loss, damage, or expense whatsoever that Krypton may suffer from the breach of any of these warranties.</Box>
                <Box sx={{fontWeight: 800, fontSize: '12px', mt: 2}}>SECTION IV: GENERAL TERMS</Box>
                <Box>The terms contained in this Section IV shall apply, in addition to any another applicable Section of this Agreement, to any Party with whom Krypton has, or anticipates having, a contractual relationship with.</Box>
                <Box sx={{fontWeight: 800, mt: 1}}>1. Confidentiality</Box>
                <Box>1.1 All Confidential Information disclosed to the Party shall be held in strict confidence and shall not directly or indirectly be disclosed, published, revealed, divulged or other made known to any third party, except as may be required by applicable law, without the prior written consent of Krypton.</Box>
                <Box>1.2 Any Confidential Information disclosed to the representatives of the Party shall be made on a “need to know’ basis only and only to the extent necessary for the furtherance of this Agreement.</Box>
                <Box>1.3 If the Party, at any time, discovers that Confidential Information has been disclosed inadvertently or in violation of this Agreement, the Party shall promptly take all commercially reasonable steps to regain possession of the disclosed Confidential Information, prevent any further disclosure, and shall advise Krypton of both the nature of such disclosure and the steps taken to regain possession and prevent further disclosure.</Box>
                <Box sx={{fontWeight: 800, mt: 1}}>2. Indemnification</Box>
                <Box>2.1 The Party shall indemnify, defend, protect, hold harmless, and at Krypton’s election, defend Krypton, its directors, officers, employees, and agents, against and from any and all damages, losses, liabilities, obligations, penalties, claims, causes of action, litigation, demands, judgments, suits, proceedings, settlements, fees and expenses, including but not limited to, reasonable attorney and expert fees, litigation fees, settlement fees, and court costs, arising from or related to any act or omission of the Party, its respective directors, officers, employees, and agents, related to or as part of its obligations under this Agreement.</Box>
                <Box sx={{fontWeight: 800, mt: 1}}>3. Limitation of Liability</Box>
                <Box>3.1 Krypton, its directors, officers, employees, and agents, shall not be held liable for the Party’s breach or termination of this Agreement or for any acts or omissions by Krypton in connection with this Agreement for any special, incidental, punitive, indirect, or consequential damages of any kind, however caused.</Box>
                <Box>3.2. Krypton shall not be held liable to the Party in contract, tort, or otherwise for, including without limitation, any loss of business opportunity, profits, sales, business, goodwill, or reputation in connection with this Agreement.</Box>
                <Box>3.3 Any claim by the Party against Krypton must be made within one ninety (90) days after the Party becomes aware of any circumstance giving rise to a claim. Failure by the Party to make said claim within ninety (90) days shall constitute a bar or irrevocable waiver to any claim, either directly or indirectly, in contract, tort, or otherwise in connection with this Agreement.</Box>
                <Box sx={{fontWeight: 800, mt: 1}}>4. Export Control</Box>
                <Box>4.1 The Party shall comply with all applicable United States export control laws and regulations, including, but not limited to, the requirements of the International Traffic in Arms Regulation (ITAR), 22 C.F.R. 120 et seq., and the Export Administration Regulations, 15 C.F.R. 730-774. The Party shall obtain all required export licenses and agreements necessary to perform Party’s obligations, as applicable.</Box>
                <Box>4.2 The Party shall not transfer any export-controlled item to a person who is not a “U.S. Person” as defined by ITAR, 22 C.F.R. 120.15, without the authority of a United States Government export license, technical assistance agreement, or other authority.</Box>
                <Box>4.3 The Party shall obtain a United States Government export license, export agreement, or applicable license exemption or exception prior to the transfer of any export-controlled item to any U.S. Person that is employed by any “Foreign Person” within the meaning of ITAR, 22 C.F.R. 120.16.</Box>
                <Box>4.4 The Party shall ensure all technical data subject to ITAR shall (i) only be used in performance of obligations required by this Agreement; (ii) not disclose any technical data to any Non U.S. Person unless said person is expressly authorized pursuant to an export license or export agreement; and (iii) not disclose any technical data to any U.S. Person unless said person is geographically located in the U.S.</Box>
                <Box sx={{fontWeight: 800, mt: 1}}>5. Force Majeure</Box>
                <Box>5.1 Any prevention, delay, or stoppage due to strikes, lockouts, labor disputes, acts of God, pandemics, freight embargoes governmental restrictions, governmental regulations, governmental controls, judicial orders, enemy or hostile governmental action, civil commotion, fire or other casualty, or causes beyond the reasonable control of the party obligated to perform shall constitute a Force Majeure Condition and excuse the performance by that party for a period equal to any prevention, delay, or stoppage. If a party is affected by a Force Majeure Condition, that party shall immediately provide the other party with written notice of the Force Majeure Condition and shall provide a good faith estimate of the duration of the Force Majeure Condition.</Box>
                <Box sx={{fontWeight: 800, mt: 1}}>6. Default</Box>
                <Box>6.1 Each of the following shall constitute a Default:</Box>
                <Box>(a) The failure of the Party to perform, in whole or in part, any of its obligation pursuant to this Agreement and such failure or nonperformance shall continue for a period of thirty (30) days after written notice has been given by Krypton;</Box>
                <Box>(b) The Party becomes insolvent or takes an action to file a petition under any section or chapter of the United States Bankruptcy Code, as amended from time to time, or under any similar law or statute of the United States or any state thereof;</Box>
                <Box>(c) A petition is filed against the Party under any law or statute of the United States or any state thereof, or the Party notifies Krypton that it knows such a petition will be filed;</Box>
                <Box>(d) The appointment of a receiver or trustee to take possession of the Party’ assets;</Box>
                <Box>(e) Any representation or warranty by the Party in this Agreement or in any certificate, statement or other document furnished pursuant to this Agreement that proves to be incorrect in any material respect, or</Box>
                <Box>(f) The failure by the Party to comply with the any term or condition of this Agreement on three (3) occasions during any twelve (12) month period, regardless if such failure was remedied.</Box>
                <Box>6.2 In the event of any Default by the Party, Krypton, at its option, in addition to all other legal rights and remedies: may terminate this Agreement and without further notice repossess, and require the Party to transfer title, any partially completed Goods, Materials, or Software the Party has produced or acquired pursuant to this Agreement.</Box>
                <Box>6.3 Nothing in this Section 6 shall be deemed to affect Krypton’s right to indemnification for liability arising prior to the termination of this Agreement.</Box>
                <Box>6.4 All rights, powers and remedies of Krypton under this Agreement shall be cumulative and not alternative and shall be in addition to all rights, powers and remedies given to Krypton by law, and the exercise of one or more rights or remedies shall not impair Krypton’s right to exercise any other right or remedy.</Box>
                <Box sx={{fontWeight: 800, mt: 1}}>7. Warranties</Box>
                <Box>7.1 Krypton and the Party warrants that they have the authority to enter into this Agreement and will comply with all relevant regulations in force as of the date of this Agreement in relation to the Work provided pursuant to this Agreement.</Box>
                <Box>7.2 Krypton and the Party warrants that any information provided pursuant to this Agreement is true, accurate, complete, and not misleading in any respect.</Box>
                <Box>7.3 The Party acknowledges that Krypton makes no other warranties other than those provided in this Agreement, express or implied, unless accepted in writing by Krypton and the Party. All other warranties, to the extent permitted by law, are excluded from this Agreement.</Box>
                <Box sx={{fontWeight: 800, mt: 1}}>8. Notices</Box>
                <Box>8.1 Except as otherwise provided herein, any statement, notice, or other communication that the Party may desire or be required to give to Krypton shall be deemed sufficiently given if sent by nationally recognized overnight delivery service or by registered or certified mail, return receipt requested, addressed to Krypton Solutions, LLC, Attention: General Counsel, 3060 Summit Avenue, Plano, Texas 75074, with a required copy sent by electronic mail to Anand.Patel@krypton-solutions.com.</Box>
                <Box sx={{fontWeight: 800, mt: 1}}>9. Entire Agreement</Box>
                <Box>9.1 This document, together with all referenced documents herein, constitutes the entire Terms and Conditions for the Agreement between Krypton and the Party and supersedes all previous and contemporaneous communications, whether written or oral, with respect to the Agreement.</Box>
                <Box>9.2 Acceptance of the Terms and Conditions is strictly limited to the terms and conditions contained herein. Any other terms, conditions, or modifications shall have no effect unless accepted in writing by Krypton and the Party.</Box>
                <Box sx={{fontWeight: 800, mt: 1}}>10. Assignment</Box>
                <Box>10.1 The Party shall not voluntarily, by operation of law, or otherwise assign or transfer all or any part of this Agreement, without the prior written consent of Krypton. Such request for consent shall be submitted to Krypton at least thirty (30) days in advance of the date on which the Party desires to make such assignment.</Box>
                <Box sx={{fontWeight: 800, mt: 1}}>11. Governing Law</Box>
                <Box>11.1 This Agreement shall be governed by and construed in accordance with the laws of the State of Texas, without reference to principles respecting conflicts of law. Any action brought to enforce or interpret this Agreement shall be brought in the court of appropriate jurisdiction in Collin County.</Box>
                <Box sx={{fontWeight: 800, mt: 1}}>12. Disputes</Box>
                <Box>12.1 Any controversy, dispute, or claim arising out of, in connection with, or in relation to, the interpretation, performance or breach of this Agreement, including, without limitation, the validity, scope, and enforceability of this Section 14, shall be solely and finally settled by arbitration conducted in Dallas, Texas, by and in accordance with the then existing rules for commercial arbitration of the American Arbitration Association, or any successor organization.</Box>
                <Box>12.2 The Party agrees that such arbitration shall be conducted by a single arbitrator; provided that such arbitrator shall be experienced in deciding cases concerning the matter which is the subject of the dispute. The arbitrator shall: (i) have the authority to compel adequate discovery for the resolution of the dispute and to award such relief as would otherwise be permitted by law; and (ii) issue a written arbitration decision, to include the arbitrator’s essential findings and conclusions and a statement of award. The arbitrator shall be authorized to award any or all remedies that would be entitled in a court of law.</Box>
                <Box sx={{fontWeight: 800, mt: 1}}>13. Attorney Fees</Box>
                <Box>13.1 In the event the Party defaults in the performance of any of the terms or conditions contained in this Agreement and Krypton places the enforcement of this Agreement, or any part thereof, or the collection of any payment due or to become due hereunder, in the hands of any attorney, the Party shall pay Krypton’s reasonable attorney fees.</Box>
            </DialogContent>
            <DialogActions sx={{display: 'flex', justifyContent: 'center'}}>
                <Button onClick={handleClose}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>}
        {!open && <Link onClick={handleOpen}>Terms & Conditions</Link>}
    </Box>;
};

export default TermsAndConditionsModal;
