import {Button, Card, CardActions, CardContent, CardHeader, Container, Typography} from '@mui/material';
import type * as React from 'react';

const AuthenticationError = () : React.ReactElement => {
    return (
        <Container sx={{mt: 4, mb: 4}}>
            <Card>
                <CardHeader title="Authentication failed"/>
                <CardContent>
                    <Typography variant="body2" color="text.secondary">
                        We were unable to complete your authentication. Please try again.
                    </Typography>
                </CardContent>
                <CardActions>
                    <Button size="small" onClick={() => {
                        window.location.href = window.location.origin;
                    }}>
                        Reload
                    </Button>
                </CardActions>
            </Card>
        </Container>
    );
};

export default AuthenticationError;
