import {Box} from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import type * as React from 'react';

type Props = {
    open : boolean;
    confirmAgreement : () => void;
};

const ConfidentialityModal = ({open, confirmAgreement} : Props) : React.ReactElement => {
    const handleClose = () => {
        confirmAgreement();
    };

    return <Box>
        <Dialog
            open={open}
            maxWidth='md'
            fullWidth={true}
            aria-labelledby="confidentiality-modal-title"
            aria-describedby="confidentiality-modal-description"
        >
            <DialogTitle id="confidentiality-modal-title">
                Confidentiality Agreement
            </DialogTitle>
            <DialogContent>
                <Box sx={{mt: 2}}>
                    Any information or materials either disclosed by Krypton herein that relates to the business or
                    activities of Krypton Solutions, LLC (“Krypton”) or any of its affiliates or subsidiaries and is not
                    generally known to the public, including but not limited to manufacturing processes, product
                    specifications and designs, technical know-how, financial information, and customer data, shall be
                    deemed Confidential Information, whether or not designated as such.
                </Box>
                <Box sx={{mt: 2}}>
                    The undersigned shall hold in confidence and not directly or indirectly disclose, publish, reveal,
                    divulge, or otherwise make known any Confidential Information to any third party, except as may be
                    required by applicable law, without the prior, written consent of Krypton. The undersigned shall
                    protect the Confidential Information with the same degree of care the undersigned would use
                    regarding its own proprietary/confidential information of like importance, but in no event less than
                    reasonable care, and shall use all reasonable endeavors to prevent unauthorized publication or
                    disclosure of the Confidential Information.
                </Box>
                <Box sx={{mt: 2}}>
                    The undersigned acknowledges that information disclosed herein may contain data subject to export
                    control restrictions and that compliance with applicable Government regulations may be necessary.
                    The undersigned agrees to obtain all necessary approvals prior to disclosing any information subject
                    to export control restrictions to foreign persons, businesses, or governments. The undersigned
                    agrees to fully comply with the United States export control laws and regulations, specifically
                    including, but not limited to, ITAR, 22 CFR 120 et seq.; Foreign Assets Control Regulations, 31
                    C.F.R. 500-598; Export Administration Regulations, 15 C.F.R. 730-774; including the requirement for
                    obtaining export licenses, if applicable.
                </Box>
                <Box sx={{mt: 2}}>
                    The undersigned acknowledges that any breach or threatened breach by it, may cause irreparable
                    damage to Krypton and agrees that Krypton shall be entitled to injunctive or other equitable relief,
                    without being required to post bond or other security, to prevent such actual or threatened
                    unauthorized use or disclosure and to specifically enforce the terms and provisions hereof. The
                    foregoing remedies are supplemental to and do not exclude any other remedies available at law or in
                    equity.
                </Box>
            </DialogContent>
            <DialogActions sx={{display: 'flex', justifyContent: 'center'}}>
                <Button onClick={handleClose}>
                    I Agree
                </Button>
            </DialogActions>
        </Dialog>
    </Box>;
};

export default ConfidentialityModal;
