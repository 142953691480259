import type {User} from '@auth0/auth0-react';
import {useAuth0} from '@auth0/auth0-react';
import type {ReactNode} from 'react';
import {createContext, useContext} from 'react';

type IdentityContext = {
    user : User;
};

const identityContext = createContext<IdentityContext | null>(null);

type Props = {
    children ?: ReactNode | undefined;
};

export const useIdentity = () : IdentityContext => {
    const identity = useContext(identityContext);

    if (!identity) {
        throw new Error('Context used outside of provider');
    }

    return identity;
};

const IdentityProvider = ({children} : Props) : JSX.Element => {
    const {user} = useAuth0();

    if (!user) {
        throw new Error('User must be signed in');
    }

    const value = {
        user: user,
    };

    return (
        <identityContext.Provider value={value}>
            {children}
        </identityContext.Provider>
    );
};

export default IdentityProvider;
