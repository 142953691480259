import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import type * as React from 'react';
import type {Project} from '@/types/fmUser';

type Props = {
    project : Project | undefined;
};

const ProjectStatusTable = ({project} : Props) : React.ReactElement => {
    return (
        <TableContainer component={Paper} sx={{mt: 2}}>
            <Table sx={{minWidth: 650}} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>Status</TableCell>
                        <TableCell>Last Updated</TableCell>
                        <TableCell>Comments</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow
                        sx={{'&:last-child td, &:last-child th': {border: 0}}}
                    >
                        <TableCell>{project?.status}</TableCell>
                        <TableCell>{project?.statusDate.toLocaleDateString()}</TableCell>
                        <TableCell>{project?.comments}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default ProjectStatusTable;
