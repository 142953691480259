import {useAuth0} from '@auth0/auth0-react';
import type {UseMutationResult} from '@tanstack/react-query';
import {useMutation, useQueryClient} from '@tanstack/react-query';
import useOidcFetch from '@/hooks/useOidcFetch';
import {apiUrl} from '@/utils/api';

export const useUpdateTermsAndConditions = () : UseMutationResult<void, unknown> => {
    const fetch = useOidcFetch();
    const {user} = useAuth0();
    const queryClient = useQueryClient();

    if (!user) {
        throw new Error('User must be signed in');
    }

    return useMutation({
        mutationFn: async () => {
            const response = await fetch(apiUrl('/terms/terms/save').toString(), {
                method: 'PUT',
            });

            if (!response.ok) {
                console.error('Failed to update terms and conditions for: ', user.email);
            }
        },
        onSuccess: async () => {
            await queryClient.invalidateQueries({queryKey: ['user']});
        },
    });
};
