import {Box} from '@mui/material';
import Typography from '@mui/material/Typography';
import type * as React from 'react';

type Props = {
    label : string;
    value : string | undefined;
};

const LabelAndValue = ({label, value} : Props) : React.ReactElement => {
    return <Box>
        <Typography
            sx={{
                fontWeight: 800,
                mr: 2,
                display: 'inline',
            }}
        >
            {label}:
        </Typography>
        <Typography sx={{display: 'inline'}}>
            {value}
        </Typography>
    </Box>;
};

export default LabelAndValue;
