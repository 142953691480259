import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import type * as React from 'react';
import ProjectStatusTable from '@/components/ProjectStatusTable';
import LabelAndValue from '@/components/TextDisplay/LabelAndValue';
import type {Project} from '@/types/fmUser';

type Props = {
    selectedProject : Project | undefined;
    setSelectedProject : (selectedProject : Project | undefined) => void;
};

const ProjectModal = ({selectedProject, setSelectedProject} : Props) : React.ReactElement => {
    const handleClose = () => {
        setSelectedProject(undefined);
    };

    return <Dialog
        open={selectedProject !== undefined}
        maxWidth='sm'
        fullWidth={true}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle id="alert-dialog-title">
            Project Detail
        </DialogTitle>
        <DialogContent>
            <LabelAndValue label='Job Number' value={selectedProject?.jobNumber}/>
            <LabelAndValue label='Project Name' value={selectedProject?.projectName}/>
            <ProjectStatusTable project={selectedProject}/>
        </DialogContent>
        <DialogActions>
            <Button onClick={handleClose} autoFocus>
                Close
            </Button>
        </DialogActions>
    </Dialog>;
};

export default ProjectModal;
