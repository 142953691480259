import {useAuth0} from '@auth0/auth0-react';
import type {UseMutationResult} from '@tanstack/react-query';
import {useMutation} from '@tanstack/react-query';
import useOidcFetch from '@/hooks/useOidcFetch';
import {apiUrl} from '@/utils/api';

type Props = {
    callback : () => void;
};
export const useUsageLog = ({callback} : Props) : UseMutationResult<void, unknown> => {
    const fetch = useOidcFetch();
    const {user} = useAuth0();

    if (!user) {
        throw new Error('User must be signed in');
    }

    return useMutation({
        mutationFn: async () => {
            const response = await fetch(apiUrl('/usage/usage/save').toString(), {
                method: 'PUT',
            });

            if (!response.ok) {
                console.error('Failed to update terms and conditions for: ', user.email);
            }
        },
        onMutate: () => {
            callback();
        },
    });
};
