import {useAuth0} from '@auth0/auth0-react';
import LogoutIcon from '@mui/icons-material/Logout';
import type {SxProps} from '@mui/material';
import {Button} from '@mui/material';
import Box from '@mui/material/Box';
import type * as React from 'react';

type Props = {
    sx ?: SxProps;
};

const UserHeaderLinks = ({sx} : Props) : React.ReactElement => {
    const {logout} = useAuth0();

    return <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        mt: 2,
        mb: 1,
        ...sx,
    }}>
        <Button
            onClick={async () => {
                await logout();
            }}
            component="label"
            startIcon={<LogoutIcon sx={{ml: 2}}/>}
            sx={{
                flexDirection: 'column',
                borderRadius: '4px',
                '&:hover': {
                    backgroundColor: '#eee',
                    color: '#3c52b2',
                },
                color: '#fff',
            }}
        >
            Sign-Out
        </Button>
    </Box>;
};

export default UserHeaderLinks;
